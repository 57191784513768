<template>
  <div class="container-page big">
    <div class="title-block-top title-block-top--full">
      <div class="title-block-top__first title-block-top__first--full">
        <h2 class="title title--h2 title--subtask">
          <span class="text-title">{{ nameTask }}</span>
          <span class="text-dot">{{ getNameCountry }}</span>
          <span class="country-flag">
            <img :src="getImageCountry" alt="country-flag" width="45" height="30" />
          </span>
        </h2>
      </div>
      <div class="title-block-top__second title-block-top__second--full" v-if="editingSubtask || creatingRequest">
        <div class="cross-cutting-tasks-button">
          <div v-if="editingSubtask" class="cross-cutting-tasks-button__left">
            <ButtonComponent @click="onOpenEditSubtaskPopup" mod="gradient-bg" class="button-redesign--mobile-full"
              >Редактировать подзадачи</ButtonComponent
            >
          </div>
          <div v-if="creatingRequest" class="cross-cutting-tasks-button__right">
            <ButtonComponent @click="onOpenCreateRequestPopup" mod="gradient-bg" class="button-redesign--mobile-full"
              >Создать запрос</ButtonComponent
            >
          </div>
        </div>
      </div>
    </div>

    <div class="cross-cutting-tasks-card">
      <div class="cross-cutting-tasks-card__item" v-for="(item, index) in arraySubtask" :key="index" :id="item.id">
        <div class="cross-cutting-tasks-card__text-block">
          <div class="cross-cutting-tasks-card__title">{{ item.name }}</div>
          <div class="cross-cutting-tasks-card__description">{{ item.comment }}</div>
        </div>
        <div class="cross-cutting-tasks-card__done">
          <div class="cross-cutting-tasks-card__done-title">{{ item.completionPercentage }}%</div>
          <div class="cross-cutting-tasks-card__done-text">выполнено</div>
        </div>
        <div class="cross-cutting-tasks-card__weight">
          <div class="cross-cutting-tasks-card__weight-title">{{ changePoints(item.weight) }}</div>
          <div class="cross-cutting-tasks-card__weight-text">вес подзадачи</div>
        </div>
      </div>
    </div>
    <ModalComponent
      title="Форма создания запроса"
      content-class="modal--new"
      width="1064"
      :themeLigth="true"
      v-model="createPopupRequest"
      @close="closePopupRequest"
      @update:modelValue="closePopupRequest"
    >
      <FormCreateRequest
        :nameTask="nameTask"
        :optionEmployees="optionEmployees"
        :optionCountry="optionCountry"
        :optionResponsibleFOIV="optionResponsibleFOIV"
        :errorCreate="errorCreate"
        @submit="createRequest"
        @close="closePopupRequest"
        ref="popupFormCreateRequest"
        :disabledButton="disabledButtonPopupRequest"
      />
    </ModalComponent>

    <ModalComponent
      title="Редактирование подзадачи"
      content-class="modal--new modal--edit-subtask"
      width="1064"
      :themeLigth="true"
      v-model="editSubtaskPopup"
      @close="onCloseEditSubtaskPopup"
      @update:modelValue="onCloseEditSubtaskPopup"
    >
      <FormEditSubtask
        :subtaskList="arraySubtasksFormat"
        :errorEdit="errorEdit"
        @submit="editSubtask"
        @close="onCloseEditSubtaskPopup"
        ref="popupEditSubtask"
      />
    </ModalComponent>

    <ModalComponent
      :title="getTitleCardRequest"
      content-class="modal--new"
      width="1064"
      :themeLigth="true"
      v-model="cardRequestPopup"
      @close="onCloseCardRequestCardRequestPopup"
      @update:modelValue="onCloseCardRequestCardRequestPopup"
    >
      <CardRequest :info="infoCardRequest" />
    </ModalComponent>
  </div>
</template>

<script>
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import showHideScroll from '@/common/mixins/showHideScroll';

  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import CardRequest from '@/modules/crossCuttingTask/components/CardRequest';
  import FormEditSubtask from '@/modules/crossCuttingTask/components/FormEditSubtask';

  import API from '../api';
  import FormCreateRequest from '../components/FormCreateRequest.vue';

  export default {
    name: 'PageTaskCard',
    components: {
      ModalComponent,
      FormCreateRequest,
      FormEditSubtask,
      CardRequest,
      ButtonComponent,
    },
    mixins: [showHideScroll, security],
    data() {
      return {
        countryId: 0,
        taskId: 0,
        nameTask: '',
        arraySubtask: [],
        createPopupRequest: false,
        editSubtaskPopup: false,
        optionEmployees: [],
        optionCountry: [],
        optionResponsibleFOIV: [],
        currentCountry: {},
        errorCreate: false,
        numberRequest: 1,
        cardRequestPopup: false,
        infoCardRequest: {},
        errorEdit: false,
        arraySubtasksFormat: [],
        disabledButtonPopupRequest: false,
        editingSubtask: false,
        creatingRequest: false,
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.AccessCrossCuttingTask).then((response) => {
        if (response.data) {
          this.countryId = this.$route?.params?.id || 0;
          this.taskId = this.$route?.params?.task || 0;

          this.loadPage();

          API.getListCountries({ taskId: this.taskId }).then((response) => {
            if (response?.data) {
              this.optionCountry = response.data.map((i) => ({ value: i.id, label: i.name }));
            }
          });

          API.getListEmployees().then((response) => {
            if (response?.data.length) {
              this.optionEmployees = response.data.map((i) => ({
                id: i.id,
                text: i.text,
              }));
            }
          });

          API.getListFOIV({
            pageNumber: 1,
            typeIds: 1,
            isActive: true,
          }).then((response) => {
            const { items = [] } = response.data;
            if (items.length) {
              this.optionResponsibleFOIV = items.map((i) => ({
                id: i.id,
                text: i.name,
              }));
            }
          });
        } else this.notAccess();
      });
      this.checkPermission(Constants.Permissions.AccessToEditingSubtaskCrossCuttingTask).then((response) => {
        if (response.data) {
          this.editingSubtask = true;
        }
      });
      this.checkPermission(Constants.Permissions.AccessToCreatingRequestCrossCuttingTask).then((response) => {
        if (response.data) {
          this.creatingRequest = true;
        }
      });
    },
    computed: {
      getNameCountry() {
        return this.currentCountry?.name || '';
      },

      getImageCountry() {
        return this.currentCountry?.picture || '';
      },

      getTitleCardRequest() {
        return `Запрос №${this.numberRequest}`;
      },
    },
    watch: {
      errorCreate(val) {
        if (val) {
          setTimeout(() => {
            this.errorCreate = false;
          }, 5000);
        }
      },

      errorEdit(val) {
        if (val) {
          setTimeout(() => {
            this.errorEdit = false;
          }, 5000);
        }
      },
    },
    methods: {
      loadPage() {
        API.getInfoPageTaskCard({ countryId: this.countryId, taskId: this.taskId }).then((response) => {
          if (response?.data) {
            const { subtasks = [], country = [], taskName = '' } = response.data;
            this.arraySubtask = subtasks;
            this.currentCountry = country;
            this.nameTask = taskName;
          }
        });
      },

      onOpenEditSubtaskPopup() {
        if (this.arraySubtask.length) {
          this.arraySubtasksFormat = this.arraySubtask.map((i) => ({
            comment: i.comment,
            completionPercentage: i.completionPercentage,
            name: i.name,
            weight: i.weight,
            id: i.id,
          }));
        }

        this.editSubtaskPopup = true;
        this.hiddenBodyScroll();
      },

      editSubtask(data) {
        API.putEditSubtask({
          head: {
            countryId: +this.countryId,
          },
          body: data,
        })
          .then(() => {
            this.loadPage();
            this.onCloseEditSubtaskPopup();
          })
          .catch((err) => {
            console.error('error methods createRequest', err);
            this.errorEdit = true;
          });
      },

      onCloseEditSubtaskPopup() {
        this.editSubtaskPopup = false;
        this.$refs.popupEditSubtask.reset();
        this.showBodyScroll();
      },

      onOpenCreateRequestPopup() {
        this.createPopupRequest = true;
        this.hiddenBodyScroll();
      },

      closePopupRequest() {
        this.createPopupRequest = false;
        this.$refs.popupFormCreateRequest.reset();
        this.showBodyScroll();
      },

      createRequest(data) {
        this.disabledButtonPopupRequest = true;
        API.postCreateRequest({ crossCuttingTaskId: this.taskId, ...data })
          .then((res) => {
            this.numberRequest = res?.data?.id || 1;
            this.infoCardRequest = data;
            this.closePopupRequest();
            this.cardRequestPopup = true;
            this.hiddenBodyScroll();
          })
          .catch((err) => {
            console.error('error methods createRequest', err);
            this.errorCreate = true;
          })
          .finally(() => {
            this.disabledButtonPopupRequest = false;
          });
      },

      onCloseCardRequestCardRequestPopup() {
        this.cardRequestPopup = false;
        this.showBodyScroll();
      },

      changePoints(item) {
        return String(item).replace(/\./g, ',');
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/title.scss';

  .cross-cutting-tasks-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &__right {
      display: flex;
      flex-wrap: wrap;
    }

    @media (max-width: $mobile) {
      &__left,
      &__right {
        width: 100%;
      }

      &__left {
        margin-bottom: 8px;
      }

      &__filter {
        margin-top: 8px;
      }
    }
  }

  .country-flag {
    width: 45px;
    height: 30px;
    vertical-align: middle;
    display: inline-block;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media (max-width: $laptop) {
      width: 37px;
      height: 24px;
    }
  }

  .text-title {
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    margin-right: 10px;
  }

  .text-dot {
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    position: relative;
    padding-left: 16px;
    margin-right: 15px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      background: $base;
      border-radius: 50%;
    }

    @media (max-width: $laptop) {
      margin-right: 8px;
    }
  }

  .cross-cutting-tasks-card {
    color: $base;
    &__item {
      padding: 24px 0 24px 24px;
      margin-bottom: 24px;
      border-radius: 4px;
      border: 1px solid $grey;
      background: $light-grey;
      display: flex;
      align-items: flex-start;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      color: inherit;
      font: $fira-20-M;
      margin-bottom: 8px;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__description {
      color: $light-grey-12;
      font: $fira-16;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__done,
    &__weight {
      width: 200px;
      margin-left: 16px;
      &-title {
        font: $fira-24-M;
        color: $blue;
      }

      &-text {
        color: $light-grey-12;
        font: $fira-16;
      }
    }

    &__text-block {
      width: calc(100% - (400px + 32px));
      position: relative;
      padding-right: 70px;
    }

    @media (max-width: $tablet) {
      &__item {
        flex-wrap: wrap;
        padding: 24px;
      }

      &__text-block {
        width: 100%;
        margin-bottom: 16px;
      }

      &__done {
        margin-left: 0;
      }
    }

    @media (max-width: $laptop) {
      &__item {
        padding: 24px 0;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $grey;
        margin-bottom: 0;
      }
      &__title {
        font-size: 16px;
        line-height: 24px;
        padding-right: 40px;
      }

      &__description {
        font-size: 14px;
        line-height: 16px;
      }

      &__text-block {
        padding-right: 0;
      }

      &__done,
      &__weight {
        width: 138px;
        &-title {
          font-size: 20px;
          line-height: 24px;
        }

        &-text {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
</style>
