import axios from 'axios';

import { authAxios } from '@/common/axios';

export default {
  // метод для Put запроса
  // преобразует данные для тела запроса
  convertPutMeth(params) {
    let convert = '';
    for (var key in params) {
      if (!convert) {
        convert += `${key}=${params[key]}`;
      } else {
        convert += `&${key}=${params[key]}`;
      }
    }
    return convert;
  },

  // для страницы "Мониторинг реализации СЗ МПК", получение данных по тепловой карте
  getCountriesInTasks() {
    return axios.get('/api/crosscuttingtask/countriesintasks');
  },

  // для страницы "Мониторинг реализации СЗ МПК", получение теманических блоков
  getThematicBlocks() {
    return axios.get('/api/crosscuttingtask/thematicblocks');
  },

  // для страницы "Мониторинг реализации СЗ МПК",
  // фильтр по выбранным категориям из выпадашек
  // входные параметры:
  // CountryId: integer
  // ThematicBlockId: integer
  // KpiFrom: number
  // KpiTo: number
  getSearchCrosscuttIngTask(params) {
    return axios.get('/api/crosscuttingtask/search', { params });
  },

  // для страницы "Сквозные задачи по стране", получение данных на страницу
  // входные параметры:
  // countryId: integer
  getInfoDetailPageCrossCuttingTask(params) {
    return axios.get('/api/crosscuttingtask/tasksbycountry', { params });
  },

  // для страницы "Карточка задачи", получение данных на страницу
  // входные параметры:
  // countryId: integer
  // taskId: integer
  getInfoPageTaskCard(params) {
    return axios.get('/api/crosscuttingtask/taskbycountry', { params });
  },

  // создание СЗ
  postCreateCrossCuttingTask(params) {
    return axios.post('/api/crosscuttingtask/create', params);
  },

  // получение списка ФОИВ сотрудников
  getListFOIV(params) {
    return authAxios.get('/api/organization/searchShortFormat', { params });
  },

  // получение списка сотрудников
  getListEmployees() {
    return axios.get('/api/task/getEmployeeList');
  },

  // получение списка стран по задаче
  getListCountries(params) {
    return axios.get('/api/crosscutingtaskrequest/countriesfromtask', { params });
  },

  // редактирование подзадачи
  putEditSubtask(params) {
    const { head = {}, body = {} } = params;
    const data = this.convertPutMeth(head);
    return axios.put(`/api/crosscuttingtask/updatesubtaskbycountry?${data}`, body);
  },

  // создание Запроса
  postCreateRequest(params) {
    return axios.post('/api/crosscutingtaskrequest/create', params);
  },
};
