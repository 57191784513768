<template>
  <form action="/" class="form-edit-subtask">
    <div class="form-edit-subtask__item" v-for="(item, index) in list" :key="item.name">
      <div class="coll coll-12">
        <ComponentInput
          :label="`Название подзадачи ${index + 1}`"
          placeholder=""
          v-model="item.name"
          mod="rectangular"
          :disabled="true"
        />
      </div>
      <div class="coll coll-6 coll-m-12">
        <ComponentInput
          label="Вес"
          placeholder=""
          v-model="item.weight"
          mod="rectangular"
          type="number"
          required
          :error="v$.list.$each.$response.$errors[index].weight.length"
        />
        <p
          class="invalid-msg"
          v-if="v$.list.$each.$response.$errors[index]?.weight[0]?.$validator === nameValid.REQUIRED"
        >
          Поле обязательно для заполнения
        </p>
        <p
          class="invalid-msg"
          v-else-if="v$.list.$each.$response.$errors[index]?.weight[0]?.$validator === nameValid.MIN_VALUE"
        >
          Минимальное значение 0,1
        </p>
        <p
          class="invalid-msg"
          v-else-if="v$.list.$each.$response.$errors[index]?.weight[0]?.$validator === nameValid.MAX_VALUE"
        >
          Максимальное значение 1
        </p>
        <p
          class="invalid-msg"
          v-else-if="v$.list.$each.$response.$errors[index]?.weight[0]?.$validator === nameValid.MAX_LENGTH"
        >
          Максимальное количество цифр после запятой не должно превышать одного
        </p>
      </div>
      <div class="coll coll-6 coll-m-12">
        <ComponentInput
          label="Процент выполнения"
          placeholder=""
          v-model="item.completionPercentage"
          mod="rectangular"
          type="number"
          required
          :error="v$.list.$each.$response.$errors[index].completionPercentage.length"
        />
        <p
          class="invalid-msg"
          v-if="v$.list.$each.$response.$errors[index]?.completionPercentage[0]?.$validator === nameValid.REQUIRED"
        >
          Поле обязательно для заполнения
        </p>
        <p
          class="invalid-msg"
          v-else-if="
            v$.list.$each.$response.$errors[index]?.completionPercentage[0]?.$validator === nameValid.MIN_VALUE
          "
        >
          Минималое значение 0
        </p>
        <p
          class="invalid-msg"
          v-else-if="
            v$.list.$each.$response.$errors[index]?.completionPercentage[0]?.$validator === nameValid.MAX_VALUE
          "
        >
          Максимальное значение 100
        </p>
      </div>
      <div class="coll coll-12">
        <ComponentInput label="Комментарий" placeholder="Введите текст" v-model="item.comment" mod="rectangular" />
      </div>
    </div>
    <div class="coll coll-12" v-if="errorEdit">
      <p class="error-content">Произошла ошибка, пожалуйста, повторите попытку.</p>
    </div>
    <div class="coll coll-12" v-else-if="errorHeavyWeight">
      <p class="error-content">Сумма весов подзадач в рамках одной Сквозной задачи должна быть равна 1</p>
    </div>
    <div class="form-edit-subtask__button">
      <ButtonComponent
        @click.prevent.stop="onEdit"
        mod="gradient-bg"
        class="button-redesign--map-apply"
        :disabled="getValid"
        >Сохранить</ButtonComponent
      >
      <ButtonComponent @click.prevent.stop="onClose">Отмена</ButtonComponent>
    </div>
  </form>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { helpers, maxLength, maxValue, minValue, required } from '@vuelidate/validators';

  import ComponentInput from '@/common/components/ComponentInput.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';

  import constants from '../constants.js';

  export default {
    name: 'FormEditSubtask',
    components: { ComponentInput, ButtonComponent },
    props: {
      subtaskList: {
        type: Array,
        default: () => [],
      },
      errorEdit: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        list: JSON.parse(JSON.stringify(this.subtaskList)),
        errorHeavyWeight: false,
        nameValid: constants.VALID_NAME,
      };
    },
    computed: {
      getTotalWeight() {
        return this.list.reduce((accumulator, currentValue) => accumulator + currentValue.weight, 0);
      },

      getHeavyWeight() {
        return this.getTotalWeight !== 1;
      },

      getValid() {
        return this.v$.$invalid;
      },
    },
    watch: {
      subtaskList: {
        handler(val) {
          this.list = val;
        },
        deep: true,
      },

      list: {
        handler() {
          this.errorHeavyWeight = false;
        },
        deep: true,
      },
    },
    mounted() {
      const form = document.querySelector('.form-edit-subtask');
      form.addEventListener('wheel', function () {
        if (document.activeElement.type === 'number') {
          document.activeElement.blur();
        }
      });
    },
    methods: {
      onEdit() {
        this.v$.$touch();
        if (this.v$.$invalid || this.getHeavyWeight) {
          if (this.getHeavyWeight) {
            this.errorHeavyWeight = true;
          }
          return;
        }

        this.list = this.list.map((i) => ({
          ...i,
          comment: i.comment || '',
          completionPercentage: i.completionPercentage || 0,
        }));

        this.$emit('submit', this.list);
      },
      onClose() {
        this.$emit('close');
      },
      // вызывается через ref
      /**@public */
      reset() {
        this.list = [];
      },
    },

    setup: () => ({ v$: useVuelidate() }),
    validations: {
      list: {
        $each: helpers.forEach({
          weight: {
            required,
            minValue: minValue(0.1),
            maxValue: maxValue(1),
            maxLength: maxLength(3),
          },
          completionPercentage: {
            required,
            minValue: minValue(0),
            maxValue: maxValue(100),
          },
        }),
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../styles/coll.scss';

  .form-edit-subtask {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;

    &__title {
      font: $fira-20-M;
      margin: 0 8px 24px;
    }

    &__button {
      display: flex;
      width: calc(100% - 16px);
      margin: 24px 8px 0;
    }

    &__item {
      display: flex;
      flex-wrap: wrap;

      &:not(&:first-of-type) {
        margin-top: 25px;
      }
    }
  }
</style>
