const KPI = [
  {
    value: 1,
    label: '0-10%',
  },
  {
    value: 2,
    label: '10-20%',
  },
  {
    value: 3,
    label: '20-30%',
  },
  {
    value: 4,
    label: '30-40%',
  },
  {
    value: 5,
    label: '40-50%',
  },
  {
    value: 6,
    label: '50-60%',
  },
  {
    value: 7,
    label: '60-70%',
  },
  {
    value: 8,
    label: '70-80%',
  },
  {
    value: 9,
    label: '80-90%',
  },
  {
    value: 10,
    label: '90-100%',
  },
];

// цвета для тепловой карты от 0 до 19
const COLORS = [
  '#DBE1E6',
  '#D6DFE7',
  '#CEDDEA',
  '#C9DBEC',
  '#C5DAEE',
  '#BFD9F0',
  '#B7D6F3',
  '#AED4F7',
  '#96CCFF',
  '#96CCFF',
  '#82C3FF',
  '#82C3FF',
  '#67B6FF',
  '#67B6FF',
  '#2995F7',
  '#2995F7',
  '#0D70CD',
  '#0D70CD',
  '#01519B',
  '#01519B',
];

const VALID_NAME = {
  MIN_VALUE: 'minValue',
  MAX_VALUE: 'maxValue',
  MAX_LENGTH: 'maxLength',
  REQUIRED: 'required',
};

export default {
  KPI,
  COLORS,
  VALID_NAME,
};
